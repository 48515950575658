<template>
	<div id="opacityPage" class="row-space-tbf history-page opacity-page" v-if="loaded">
		<div class="space-left"><div class="go-back" @click="$router.push({name: 'procedure-show', params: {slug: $route.params.slug}})"><icon-arrow-back /></div></div>
		<div class="content">
			<div class="header-form">
				<h1 class="title">{{ selectedVersion.created_at | moment('DD MMMM YYYY, HH:mm')}}</h1>

				<div class="actions" :class="[{'restore': $resize && !$mq.above(781)}]">
					<button class="btn-tbf blue center restore-version" @click="restoreVersion" v-if="procedure_versions[0].id != selectedVersion.id">
						<div class="text">{{ $t('procedures.restore_btn') }}</div>
					</button>
					<button v-if="$resize && !$mq.above(781)" class="btn-tbf grey center restore-version" @click="showModal('history_version')">
						<div class="icon">
							<div class="text">{{ $t('procedures.view_versions') }}</div>
						</div>
					</button>
				</div>
				<div @click="restoreVersion" v-if="$resize && !$mq.above(780) && procedure_versions[0].id != selectedVersion.id" class="btn-tbf-link-version">{{ $t('procedures.restore_btn') }}</div>
			</div>
			<div class="body-version">
				<div v-html="comparedHTML"></div>
			</div>
		</div>
		<div v-if="$resize && $mq.above(781)" class="space-right history-list">
			<div class="title">{{ $t('procedures.version_history') }}</div>
			<div class="list">
				<div class="item" v-for="version in procedure_versions" v-bind:class="{active: version.id == selectedVersion.id}" @click="viewVersion(version)">
					<div class="date">{{ version.created_at |  moment('DD MMMM YYYY, HH:mm') }}</div>
					<div class="user">{{ version.username }}</div>
				</div>
			</div>
		</div>
		<div v-if="$resize && $mq.below(780)" class="space-right"></div>
	</div>
</template>

<script>
	import IconArrowBack from '../../Icons/ArrowLong'

	export default {
		components: {
			IconArrowBack,
		},
		data() {
			return {
				loaded: false,
				procedure_versions: [],
				selectedVersion: {body: '', previous_history: {body: ''}},
				comparedHTML: ''
			};
		},
		async mounted(){
			await this.getProcedureVersions();

			this.$root.$on('refreshVersionsHistory', () => {
				this.getProcedureVersions()
			})
			this.$root.$on('ViewVersionsHistory', (version) => {
				this.viewVersion(version)
			})
		},
		methods: {
			async getProcedureVersions(){
				await axios.get(`${this.$route.params.slug}/procedure-histories`)
				.then(({data}) => {
					this.procedure_versions = data.data
					this.selectedVersion = data.data[0]
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.comparedHTML = diff(this.selectedVersion.previous_history ? this.selectedVersion.previous_history.body : '', this.selectedVersion.body, 'modification')
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							document.getElementById('opacityPage').classList.add('show')
						}, 0)
					}, 0)
				})
			},
			viewVersion(version){
				this.selectedVersion = version
				this.comparedHTML = diff(this.selectedVersion.previous_history ? this.selectedVersion.previous_history.body : '', this.selectedVersion.body, 'modification')
			},
			restoreVersion(){
				this.$root.$emit('open_modal', 'restore_version', {version: this.selectedVersion});
			},
			showModal(type, data = this.procedure_versions, itemId = this.selectedVersion){
				this.$root.$emit('open_modal', type, data, itemId);
			}
		}
	};
</script>

<style lang="scss">
	.btn-tbf-link-version{
		top: 70px;
		position: absolute;
		align-items: center;
		cursor: pointer;
		overflow-y: hidden;
		padding: 0;
		border: 0;
		background: transparent;
		text-decoration: underline;
		font-size: 12px;
	}
	.row-space-tbf{
		&.history-page{
			.content{
				.header-form{
					.title{
						text-transform: capitalize;
					}
					.actions{
						padding-right: 30px;
						&.restore {
							padding-right: 0;
							.btn-tbf {
								&.grey {
									width: 80px;
								}
								&.blue {
									display: none;
								}
							}
						}
					}
				}
				.body-version{
					padding: 30px 30px 30px 0;
					@media (max-width: 780px) {
						padding: 30px 0 30px 0;
					}
					ul{
						padding-left: 15px;
					}
					ins{
						text-decoration: none;
						&.modification{
							background-color: #a6ffbb;
						}
					}
					del{
						text-decoration: line-through;
						&.modification{
							background-color: #ffa4b1;
						}
					}
					img{
						max-width: 100%;
						object-fit: cover;
						height: auto;
					}
				}
			}
			.space-right{
				&.history-list{
					height: 100vh;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					width: 300px;
					flex: 0 0 300px;
					box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
					overflow-y: auto;
					// border-left: 1px solid $borderColor;
					.title{
						height: 100px;
						min-height: 100px;
						display: flex;
						align-items: center;
						padding: 0 30px;
						color: $primary;
						font-size: 18px;
						font-weight: 500;
						border-bottom: 1px solid $borderColor;
						width: 100%;
					}
					.list{
						width: 100%;
						.item{
							width: 100%;
							padding: 20px 30px;
							border-bottom: 1px solid $borderColor;
							cursor: pointer;
							&:hover{
								background: #FCFCFC;
							}
							&.active{
								background: #f9f9f9;
							}
							.date{
								font-weight: 500;
								font-size: 14px;
								margin-bottom: 5px;
								text-transform: capitalize;
							}
							.user{
								color: $grey;
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}
</style>